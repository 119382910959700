const config: {
    base_url: {
        local: string,
        development: string,
        production: string,
        stagingApi: string
    },
    result_code: number | string,
    default_headers: AxiosHeaders,
    request_timeout: number
} = {
    // nt
    // api请求基础路径
    base_url: {
        // 本地环境接口
        // local : 'http://192.168.110.41:32000',
        local: 'https://www.yiguanche.pro:32000',
        // 开发环境接口
        // development : 'http://192.168.110.97:32000',
        // development : 'http://192.168.110.96:32000',
        // development: 'https://www.yiguanche.pro:32000',
        // development : 'http://192.168.110.41:32000',
        development: 'http://192.168.110.64:32000',
        // 生产环境接口
        production: 'https://www.yiguanche.pro:32000',
        // 打包测试环境接口
        stagingApi: 'http://192.168.110.96:32000',
    },
    result_code: 200,
    default_headers: 'application/x-www-form-urlencoded',
    // default_headers: 'application/json',
    request_timeout: 60000
}

export { config }